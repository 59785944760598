<template>
    <div class="dashboard">
            <div class="drawer-closed" v-show="!open">
                <div class="drawer-closed__content">
                    <div class="drawer-closed__image">
                        <img :src="user.personal_photo" @error="replaceByDefault" class="" alt="Белтаможсервис">
                    </div>
                    <button @click="open = true" class="drawer-closed__arrow">
                        <i class="fas fa-arrow-right"></i>
                    </button>

                    <div class="drawer-closed__items">
                        <router-link v-for="route in commonRoutes"
                                     :key="'drawer_'+route.routeName"
                                     tag="a"
                                     class="drawer-closed__item"
                                     :to="{name:route.routeName}">
                            <i :class="route.icon"></i>
                        </router-link>

                        <div v-if="user.uf_client_status === '4'">
                            <router-link v-for="route in clientRoutes"
                                         tag="a"
                                         :key="'drawer_'+route.routeName"
                                         class="drawer-closed__item"
                                         :to="{name:route.routeName}">
                                <i :class="route.icon"></i>
                            </router-link>
                        </div>


                        <div  v-if="user.groups && user.groups.includes('21')">
                            <router-link v-for="route in contractAdminRoutes"
                                         :key="'drawer_'+route.routeName"
                                         tag="a"
                                         class="drawer-closed__item"
                                         :to="{name:route.routeName}">
                                <i :class="route.icon"></i>
                            </router-link>


                            <div v-if="user.groups.includes('21')">
                                <router-link v-for="route in adminRoutes"
                                             :key="'drawer_'+route.routeName"
                                             tag="a"
                                             class="drawer-closed__item"
                                             :to="{name:route.routeName}"
                                ><i :class="route.icon"></i>
                                </router-link>
                            </div>

                            <router-link v-for="route in serviceRoutes"
                                         :key="'drawer_'+route.routeName"
                                         tag="a"
                                         class="drawer-closed__item"
                                         :to="{name:route.routeName}">
                                <i :class="route.icon"></i>
                            </router-link>
                        </div>
                    </div>

                </div>
            </div>

            <div
                class="drawer-opened sm:w-1/10 xl:w-2/12"
                v-show="open">

                <div class="drawer-opened__head">
                    <div>
                        <div class="drawer-opened__image">
                            <img :src="user.personal_photo" @error="replaceByDefault">
                        </div>
                    </div>
                    <div class="drawer-opened__user">
                        <p>{{ user.name }}</p>
                        <p>{{ user.last_name }}</p>
                    </div>

                    <button class="drawer-opened__arrow" @click="open = false"><i
                        class="fas fa-arrow-left"></i>
                    </button>
                </div>
                <div class="drawer-opened__login">
                    {{ user.login }}
                </div>

                <div class="drawer-opened__items">
                    <router-link v-for="route in commonRoutes"
                                 :key="route.routeName"
                                 tag="a"
                                 class="drawer-opened__item"
                                 :to="{name:route.routeName}">
                        <i class="drawer-opened__icon" :class="route.icon"></i>
                        <span class="drawer-opened__item-text">{{ route.name }}</span>
                    </router-link>

                    <div  v-if="user.uf_client_status === '4'">
                        <router-link v-for="route in clientRoutes"
                                     :key="route.routeName"
                                     tag="a"
                                     class="drawer-opened__item"
                                     :to="{name:route.routeName}">
                            <i class="drawer-opened__icon" :class="route.icon"></i>
                            <span class="drawer-opened__item-text">{{ route.name }}</span>
                        </router-link>
                    </div>

                </div>
                <div v-if="user.groups && user.groups.includes('21')" class="drawer-opened__admin-routes">

                    <router-link v-for="(route, idx) in contractAdminRoutes"
                                 :key="route.routeName + idx.toString()"
                                 tag="a"
                                 class="drawer-opened__item "
                                 :to="{name:route.routeName}">
                        <i class="drawer-opened__icon" :class="route.icon"></i>
                        <span class="drawer-opened__item-text">{{route.name}}</span>
                    </router-link>

                    <div v-if="user.groups && user.groups.includes('22')">
                        <router-link v-for="route in adminRoutes" :key="route.routeName"
                            tag="a"
                            class="drawer-opened__item"
                            :to="{name:route.routeName}"
                        >
                            <i class="drawer-opened__icon" :class="route.icon"></i>
                            <span class="drawer-opened__item-text">{{ route.name }}</span>
                        </router-link>
                    </div>
                </div>

                <router-link v-for="route in serviceRoutes"
                             :key="route.routeName"
                             tag="a"
                             class="drawer-opened__item"
                             :to="{name:route.routeName}">
                    <i class="drawer-opened__icon" :class="route.icon"></i>
                    <span class="drawer-opened__item-text">{{ route.name }}</span>
                </router-link>
            </div>

            <div class="w-10/12 dashboard__view">
                <router-view></router-view>
            </div>
            <NotificationModal v-if="showNotify"></NotificationModal>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {commonRoutes,clientRoutes, contractAdminRoutes, adminRoutes, serviceRoutes} from "@/menu/dashboard";
import NotificationModal from '@/components/user/NotificationModal'

export default {
    meta: {
        title: 'Личный кабинет'
    },
    components:{
        NotificationModal
    },
    name: "Dashboard",
    data: () => ({
        open: true,
        timer: false,
        commonRoutes,
        clientRoutes,
        contractAdminRoutes,
        adminRoutes,
        serviceRoutes
    }),
    onIdle() {
        this.$notify({
            title: 'Внимание',
            text: 'Через 15 секунд ваша сессия завершится',
            type: 'warn'
        })
        setTimeout(() => {
            this.$store.dispatch('auth/logout')
        }, 15000)
    },
    created() {
        this.$store.dispatch('catalogs/getCountries')
    },
    computed: {
        ...mapGetters({
            user: 'auth/user'
        }),
        showNotify(){
            return  Number.parseInt(this.user.uf_client_status) === 4 && Number.parseInt(this.user.uf_notified) !== 1
        }
    },
    methods: {
        replaceByDefault(e) {
            e.target.src = require('@/assets/img/icons/person-xl.svg')
        }

    }
}
</script>

<style scoped>
.dashboard{
    @apply flex h-full w-full min-h-screen dark:bg-gray-900 bg-white text-black dark:text-white pt-6
}
.drawer-closed{
    @apply dark:bg-gray-800 bg-blue-100 dark:text-white text-gray-600
}
.drawer-closed__content{
    @apply py-7 mx-2
}
.drawer-closed__image{
    @apply rounded-full overflow-hidden
    sm:w-8 sm:h-8
    xl:w-10 xl:h-10
}
.drawer-closed__arrow{
    @apply w-full p-3
    sm:invisible
    xl:visible
}
.drawer-closed__items{
    @apply  flex flex-col items-center text-xl
}
.drawer-closed__item{
    @apply my-2 block
}
.drawer-opened{
    @apply flex flex-col overflow-hidden border-r sm:dark:bg-gray-800 dark:text-gray-100 text-gray-700 bg-blue-100
    dark:border-gray-700 border-blue-100
    sm:px-2 sm:items-center
    xl:dark:bg-gray-900 xl:items-start xl:px-5
}
.drawer-opened__head{
    @apply py-7 flex items-center
    sm:flex-col
    xl:flex-row
}
.drawer-opened__image{
    @apply overflow-hidden rounded-full
    sm:w-8 sm:h-8
    xl:w-16 xl:h-16
}
.drawer-opened__user{
    @apply px-2
    sm:hidden
    xl:block
}
.dark .router-link-active {
    color: rgb(52, 211, 153);
}
.drawer-opened__arrow{
    @apply w-full
    sm:hidden
    xl:block xl:px-5
}
.drawer-opened__login{
    @apply break-all
    sm:hidden
    xl:block
}
.drawer-opened__items{
    @apply border-gray-300 mt-5
    sm:text-xl sm:text-center
    xl:text-base xl:border-t xl:text-left xl:w-full
}
.drawer-opened__item{
    @apply  block
    sm:my-5
    xl:my-3
}
.dashboard__view{
    @apply  h-full mx-auto relative
    sm:px-2
    xl:px-0
}
.drawer-opened__admin-routes{
    @apply border-gray-300
    xl:w-full xl:border-t
}
.drawer-opened__icon{
    @apply sm:block xl:hidden
}
.drawer-opened__item-text{
    @apply sm:hidden xl:block
}
.router-link-active {
    color: #1037b2;
}
</style>
