const  commonRoutes =  [
        {
            name:'Редактировать профиль',
            routeName:'UserEdit',
            icon:'far fa-user',

        },
    ]
const clientRoutes =  [
        {
            name:'Клиент',
            routeName:'Client',
            icon:'fas fa-house-user',

        },
    ]

const adminRoutes = [
        {
            name:'История',
            routeName:'History',
            icon:'fas fa-history',

        },
    ]

const contractAdminRoutes = [
        {
            name:'Администрирование договоров',
            routeName:'Contracts',
            icon:'fas fa-user-lock',

        },
        {
            name:'Зарегистрировать юр.лицо',
            routeName:'RegisterAsCompany',
            icon:'far fa-id-card',

        },
        {
            name:'Прикрепить документы',
            routeName:'AttachDocuments',
            icon:'far fa-user',

        },
        {
            name:'Экспорт данных клиентов',
            routeName:'ExportClients',
            icon:'fas fa-file-download',

        },
        {
            name:'Управление платежами',
            routeName:'ManagePayments',
            icon:'fas fa-file-invoice-dollar',

        },
    ]
const serviceRoutes = [
    {
        name:'Декларирование ТС',
        routeName:'TransportDeclaration',
        icon:'fa fa-car',
    }
]

export {commonRoutes, adminRoutes, contractAdminRoutes, clientRoutes, serviceRoutes}
